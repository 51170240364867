const muestra = {
    orderForm:{},
    items:{
        orderForm:{

        },
        archivo:false,
        htmlText:true
    },
    archivo:false,
    htmlText:false,
    htmlEditorText:false



}

const Html = {
    id_type_module:3,
    archivo:false,
    htmlText:false,
    htmlEditorText:true


}

export { Html }


