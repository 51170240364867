const muestra = {
    orderForm:{},
    items:{
        orderForm:{

        },
        archivo:false,
        htmlText:true
    },
    archivo:false,
    htmlText:false,
    htmlEditorText:false



}

const Card = {
    id_type_module:24,
    orderForm:{
      columnas_fila: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          label: 'Título',
          fullWidth: true
        },
        value: '',
        validation: {
          required: false
        },
        valid: true,
        touched: false
      },
    },
    archivo:false,
    htmlText:true,
    htmlEditorText:false


}

export { Card }


