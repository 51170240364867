export default class Prueba {
    static contador = 0

    static sumar() {
        this.contador = this.contador + 1
        console.log(this.contador)
    }



}