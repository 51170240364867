const muestra = {
    orderForm:{},
    items:{
        orderForm:{

        },
        archivo:false,
        htmlText:true
    },
    archivo:false,
    htmlText:false,
    htmlEditorText:false



}

const TextoEnriquecido = {
    id_type_module:19,
 
    archivo:false,
    htmlText:true,
    htmlEditorText:false


}

export { TextoEnriquecido }

